<template>
  <div>
    <div class="dk-line"></div>
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <van-cell v-for="(item,index) in newList" :key="index">
        <div :key="index" class="list-item felx flex-space" @click="jump(item.url)">
          <div class="flex-1  felx  felx-d item-l">
            <div class="item-title">{{item.title}}</div>
            <div class="item-time">{{item.createTime}}</div>
          </div>
          <img :src="item.coverImg" class="img">
        </div>
      </van-cell>

    </van-list>


  </div>
</template>

<script>
export default {
  name: "loan-info",
  data() {
    return {
      newList: [],
      loading: false,
      finished: false,
      pageNo: 1,
    }
  },
  mounted() {
    // this.getNewList();
  },
  methods: {
    //去详情
    jump(url){
      window.location.href=url;
    },
    //获取资讯
    onLoad() {
      // this.loading = true;
      this.$axios.get(`/business/websiteNews/newsForUccn`, {
        params: {pageNo: this.pageNo,pageSize:5, classify: 1}}).then((res) => {
        this.pageNo=++this.pageNo;
        let data = res.data.data.records
        this.loading = false;
        this.newList = this.newList.concat(data);
        this.finished = !data.length;

      })

    },
  },
}
</script>

<style scoped>
.dk-line {
  width: 100%;
  height: 10px;
  background: #F8F8F8;
  /*box-sizing: border-box;*/
}

.list-item {
  padding: 15px;
}

.img {
  width: 115px;
  height: 75px;
  border-radius: 5px;
}

.item-title {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  line-height: 24px;
}

.item-time {
  font-size: 11px;
  color: #999999;
}

.item-l {
  justify-content: space-between;
  height: 75px;
}
</style>
